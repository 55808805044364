<template>
  <div>

    <b-modal
        ref="sponsoredModal"
        :hide-footer="true"
        v-model="showSponsoredModal"
        ok-title="Save"
        size="lg"
    >
      <EditSponsored :post="post" @close="$refs['sponsoredModal'].hide()" @sponsorCreated="onSponsorCreated" :uid="postUid" :is-edit="true"></EditSponsored>
    </b-modal>

    <sponsored-posts-filters :viewsDate.sync="viewsRange" :position.sync="position" :sponsoredDate.sync="sponsoredDate"></sponsored-posts-filters>
<!--    <posts-filters-->
<!--        :type.sync="userRole"-->
<!--        :industry.sync="industryFilter"-->
<!--        :created.sync="created"-->
<!--        :is-blocked.sync="isBlocked"-->
<!--        :is-favourite.sync="isFavourite"-->
<!--    ></posts-filters>-->

    <b-modal
        v-model="showModal"
        ok-only
        ok-title="Close"
        size="lg"
        scrollable
    >
      <post v-if="post" :post="post"></post>
    </b-modal>

    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row class="justify-content-between">

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col
              cols="12"
              md="3"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="createdBy"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          responsive
          ref="refTable"
          class="position-relative"
          :items="fetchPosts"
          :fields="sponsoredColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(user)="data">
          <div class="d-flex">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="32"
                    :src="data.item.createdBy.profileImage"
                    :text="data.item.createdBy.name"
                    :variant="`light-${resolveUserRoleVariant(data.item.createdBy.role)}`"
                />
              </template>
              <b-link
                  target="_blank"
                  class="font-weight-bold d-block text-nowrap"
                  :to="resolvePostLink(data.item.createdBy, data.item.uid)"

              >
                {{ data.item.createdBy.name }}
              </b-link>
              <small class="text-muted">{{ data.item.createdBy.role }}</small>
            </b-media>
            <feather-icon
                @click="openModal(data.item)"
                style="cursor: pointer"
                icon="EyeIcon"
                size="18"
                class="text-gray ml-2"
            />
          </div>
        </template>

        <template #cell(validFrom)="data">
          {{ resolveCreated(data.item.validFrom, true) }}
        </template>

        <template #cell(validTo)="data">
          {{ resolveCreated(data.item.validTo, true) }}
        </template>

        <template #cell(content)="data">
          {{ shortContent(data.item.content) }}
        </template>

        <template #cell(media)="data">
          <feather-icon
              v-if="data.item.images.length > 0"
              icon="ImageIcon"
              size="18"
              class="mr-50"
          />
          <feather-icon
              v-if="data.item.videos.length > 0"
              icon="VideoIcon"
              size="18"
              class="mr-50"
          />
        </template>

        <template #cell(isBlocked)="data">
          <feather-icon
              :icon="resolveBooleanIcon(data.item.isBlocked)"
              size="18"
              class="mr-50"
              :class="data.item.isBlocked ? 'text-success' : 'text-primary'"
          />
        </template>

        <template #cell(favourite)="data">
          <feather-icon
              @click="changeFavouriteStatus(data.item)"
              style="cursor: pointer"
              icon="HeartIcon"
              size="18"
              class="mr-50"
              :class="data.item.isFavourite ? 'text-primary' : 'text-gray'"
          />
        </template>

        <template #cell(isSponsored)="data">
          <feather-icon
              :icon="resolveBooleanIcon(data.item.isSponsored)"
              size="18"
              class="mr-50"
              :class="data.item.isSponsored ? 'text-success' : 'text-primary'"
          />

        </template>

        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button @click="openSponsoredModal(data.item)" variant="success" class="ml-1" size="sm">Edit</b-button>
            <b-button @click="removeSponsored(data.item.uid)" variant="primary" class="ml-1" size="sm">Remove</b-button>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{
                dataMeta.of
              }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalCount"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown, BDropdownItem,
  BFormInput,
  BLink,
  BMedia, BPagination,
  BRow,
  BTable,
  BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import usePosts from '@/views/Posts/usePosts'
import { resolveUserRoleVariant, resolveBooleanIcon } from '@/helpers/table-resolvers'
import PostsFilters from '@/components/posts/PostsFilters'
import Post from '@/components/post/Post'
import EditSponsored from '@/components/post/EditSponsored'
import { toastMixin } from '@/mixins/toast-mixin'
import SponsoredPostsFilters from '@/components/posts/SponsoredPostsFilters'

export default {
  mixins: [toastMixin],
  components: {
    SponsoredPostsFilters,
    PostsFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    vSelect,
    Post,
    EditSponsored
  },
  data() {
    return {
      showModal: false,
      post: null,
      showSponsoredModal: false,
      postUid: null
    }
  },
  methods: {
    openDeletePostDialog(uid) {
      this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to delete post?`, {
            title: 'Delete post',
            centered: true,
            cancelVariant: 'outline-secondary',
          })
          .then(value => {
            if (value) {
              this.removePost(uid)
            }
          })
    },
    openModal(post) {
      this.post = post
      this.showModal = true
    },
    closeModal() {
      this.post = null;
      this.showModal = false
    },
    openSponsoredModal(post) {
      this.postUid = post.uid
      this.post = post
      this.showSponsoredModal = true
    },
    onSponsorCreated(uid) {
      this.$refs.sponsoredModal.hide()
      this.refetchData()
    },
    async removeSponsored(uid) {
      try {
        await this.$httpService.posts.deleteSponsoredPost(uid)
        this.refetchData()
      } catch (e) {
        this.showError()
      }
    }
  },
  setup() {
    const posts = usePosts(true)

    return {
      resolveUserRoleVariant,
      resolveBooleanIcon,
      ...posts
    }
  }
}
</script>

<style lang="scss">
.modal-header {
  display: none;
  .close {
    display: none;
  }
}
</style>
